import gsap from "gsap";
import DefaultBlock from "/scripts/default/block";

export default class Button extends DefaultBlock {
	init() {
		const el = gsap.utils.selector(this.block);

		this.DOM = {
			button: this.block,
			flair: el(".button__flair"),
		};

		this.xSet = gsap.quickSetter(this.DOM.flair, "xPercent");
		this.ySet = gsap.quickSetter(this.DOM.flair, "yPercent");
		this.hasFill = this.DOM.button.classList.contains("button--fill");
	}

	getXY(e) {
		const { left, top, width, height } =
			this.DOM.button.getBoundingClientRect();

		const xTransformer = gsap.utils.pipe(
			gsap.utils.mapRange(0, width, 0, 100),
			gsap.utils.clamp(0, 100)
		);

		const yTransformer = gsap.utils.pipe(
			gsap.utils.mapRange(0, height, 0, 100),
			gsap.utils.clamp(0, 100)
		);

		return {
			x: xTransformer(e.clientX - left),
			y: yTransformer(e.clientY - top),
		};
	}

	initEvents() {
		this.DOM.button.addEventListener("mouseenter", (e) => {
			const { x, y } = this.getXY(e);

			this.xSet(x);
			this.ySet(y);

			if (this.hasFill) {
				gsap.to(this.DOM.flair, {
					opacity: 1,
					duration: 1,
					ease: "power2.out",
				});
			} else {
				gsap.to(this.DOM.flair, {
					scale: 1,
					duration: 0.4,
					ease: "power2.out",
				});
			}
		});

		this.DOM.button.addEventListener("mouseleave", (e) => {
			const { x, y } = this.getXY(e);

			gsap.killTweensOf(this.DOM.flair);

			if (this.hasFill) {
				gsap.to(this.DOM.flair, {
					xPercent: x > 90 ? x + 20 : x < 10 ? x - 20 : x,
					yPercent: y > 90 ? y + 20 : y < 10 ? y - 20 : y,
					opacity: 0,
					duration: 1,
					ease: "power2.out",
				});
			} else {
				gsap.to(this.DOM.flair, {
					xPercent: x > 90 ? x + 20 : x < 10 ? x - 20 : x,
					yPercent: y > 90 ? y + 20 : y < 10 ? y - 20 : y,
					scale: 0,
					duration: 0.3,
					ease: "power2.out",
				});
			}
		});

		this.DOM.button.addEventListener("mousemove", (e) => {
			const { x, y } = this.getXY(e);

			gsap.to(this.DOM.flair, {
				xPercent: x,
				yPercent: y,
				duration: this.hasFill ? 1 : 0.4,
				ease: "power2",
			});
		});
	}
}
