import gsap from "gsap";
import { ScrollSmoother } from "gsap/all";
import DefaultBlock from "/scripts/default/block";
import { watchPreferredMotion } from "/utils/helpers/watchPreferredMotion";

// Register Plugins
gsap.registerPlugin(ScrollSmoother);

export default class ScrollSmooth extends DefaultBlock {
	init() {
		watchPreferredMotion(() => {
			ScrollSmoother.create({
				smooth: 1.8,
				effects: true,
			});

			document.body.classList.add("has-smooth-scroll");
		});
	}
}
