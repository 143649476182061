import DefaultFactory from "/scripts/default/factory";
import Brands from "/components/brands";
import Button from "/components/button";
import Showcase from "/components/showcase";
import Video from "/components/video";
import Subtitle from "/components/subtitle";
import MoreLinks from "/components/moreLinks";
import Demos from "/components/demos";
import Testimonials from "/components/testimonials";
import GetGsapBtn from "/components/home/getGsapBtn";
import HoverVideo from "/components/hoverVideo";
import ScrollSmooth from "/components/scroll/smooth";
import Tooltip from "/components/tooltip";
import Plugins from "/components/plugins";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { CustomEase } from "gsap/CustomEase";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

const hasWindow = typeof window !== "undefined" ? true : false;
/* Global blocks requiring global scope & adding globals for the shared header.js file */

if (hasWindow) {
	gsap.registerPlugin(
		gsap,
		ScrollTrigger,
		ScrollSmoother,
		CustomEase,
		MotionPathPlugin,
		DrawSVGPlugin
	);

	window.gsap = gsap;
	window.ScrollTrigger = ScrollTrigger;
	window.CustomEase = CustomEase;
	window.MotionPathPlugin = MotionPathPlugin;
	window.DrawSVGPlugin = DrawSVGPlugin;
}

/* All other global blocks (e.g. Buttons) */
class GlobalFactory extends DefaultFactory {
	createBlock(blockName, block) {
		switch (blockName) {
			/* Match the block name with the corresponding class that should be created */
			case "scroll-hero":
				return new ScrollSmooth(block);
			case "brands":
				return new Brands(block);
			case "button":
				return new Button(block);
			case "demos":
				return new Demos(block);
			case "get-gsap-btn":
				return new GetGsapBtn(block);
			case "hover-video":
				return new HoverVideo(block);
			case "showcase":
				return new Showcase(block);
			case "subtitle":
				return new Subtitle(block);
			case "video":
				return new Video(block);
			case "more-links":
				return new MoreLinks(block);
			case "testimonials":
				return new Testimonials(block);
			case "tooltip":
				return new Tooltip(block);
			case "plugins":
				return new Plugins(block);
			default:
				return null;
		}
	}
}

/* Init */
new GlobalFactory();
