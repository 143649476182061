import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import DefaultBlock from "/scripts/default/block";

// Register Plugins
gsap.registerPlugin(ScrollTrigger);

export default class Demos extends DefaultBlock {
	init() {
		const DOM = {
			select: this.block.querySelector(".demos__plugins-groups-select"),
			pluginSelects: this.block.querySelectorAll(".demos__plugin-select"),
			plugins: this.block.querySelectorAll(".demos__plugins-groups-plugins"),
			buttons: this.block.querySelectorAll("[data-demos-btn]"),
			demoButton: this.block.querySelector("[data-js=demo-button]"),
			docsButton: this.block.querySelector("[data-js=docs-button]"),
			iframe: this.block.querySelector(".js-demo-iframe"),
		};
		this.DOM = DOM;

		this.DOM.pluginSelects.forEach(select => this.handlePluginSelect(select))
		
		if (!this.DOM.select || !this.DOM.plugins) return;

		this.handleSelect();
	}

	initEvents() {
		const activeClass = "is-active";

		this.DOM.buttons.forEach((button) => {
			button.addEventListener("click", (e) => {

				this.updateDemo(e.target);
				
				this.DOM.buttons.forEach((button) => {
					button.classList.remove(activeClass);
				});

				button.classList.add(activeClass);
			});
		});
	}

	handleSelect() {
		const currentSelect = this.DOM.plugins[this.DOM.select.value].querySelector("select")
		const selectedOption = currentSelect.options[currentSelect.selectedIndex];
		this.updateDemo(selectedOption);

		this.DOM.select.addEventListener("change", (e) => {
			this.DOM.plugins.forEach((plugin) => {
				plugin.classList.remove("demos__plugins-groups-plugins--active");
			});

			this.DOM.plugins[this.DOM.select.value].classList.add(
				"demos__plugins-groups-plugins--active"
			);

			const currentSelect = this.DOM.plugins[this.DOM.select.value].querySelector("select")
			const selectedOption = currentSelect.options[currentSelect.selectedIndex];
			this.updateDemo(selectedOption);
		});
	}

	handlePluginSelect(select) {
		const selectedOption = select.options[select.selectedIndex];
		this.updateDemo(selectedOption);

		select.addEventListener("change", (e) => {
			const selectElement = e.target;
			const selectedOption = select.options[select.selectedIndex];

			
			this.updateDemo(selectedOption);
		});
	}

	updateDemo(value) {
		let id = value.dataset.id,
			docs = value.dataset.docs;

		this.DOM.iframe.src = `https://codepen.io/GreenSock/debug/${id}`;
		this.DOM.demoButton.setAttribute("href", `https://codepen.io/GreenSock/pen/${id}`);

		if(docs) {
			this.DOM.docsButton.setAttribute("href", docs);
		}
	}
}
