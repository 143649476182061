/**
 * Check if reduced motion is set on the users device
 */

import { gsap } from "gsap";

export const watchPreferredMotion = (callback = () => {}) => {
	const mm = gsap.matchMedia();

	mm.add("(prefers-reduced-motion: no-preference)", callback);
};
