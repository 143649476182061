import { gsap } from "gsap";
import DefaultBlock from "/scripts/default/block";

export default class HoverVideo extends DefaultBlock {
	init() {
		const DOM = {
			block: this.block,
			video: this.block.querySelector("video"),
		};

		this.DOM = DOM;
	}

	initEvents() {
		const mm = gsap.matchMedia();

		mm.add(
			"(prefers-reduced-motion: no-preference) and (min-width: 1240px)",
			() => {
				this.DOM.block.addEventListener("mouseenter", () => {
					this.playVideo();
				});
				this.DOM.block.addEventListener("mouseleave", () => {
					this.stopVideo();
				});
			}
		);
	}

	playVideo() {
		if (this.pauseTimeout) clearTimeout(this.pauseTimeout);
		this.DOM.video.currentTime = 0;
		this.DOM.video.play();
	}

	stopVideo() {
		this.pauseTimeout = setTimeout(() => {
			this.DOM.video.pause();
		}, 1000);
	}
}
