import DefaultBlock from "/scripts/default/block";
import gsap from "gsap";

export default class Testimonials extends DefaultBlock {
	init() {
		const el = {
			items: this.block.querySelectorAll(".testimonials__item"),
			controls: this.block.querySelectorAll(".testimonials__control--button"),
			previous: this.block.querySelector(".prev"),
			next: this.block.querySelector(".next"),
		};
		this.el = el;

		this.previousIndex = 0;
		this.currentIndex = 0;
		this.controlIndex = 0;

		this.handleNavigation();
		this.navigateToNextSlide = this.navigateToNextSlide.bind(this);
	}

	handleNavigation() {
		this.el.controls.forEach((control) => {
			control.addEventListener("click", (e) => {
				const index = parseInt(e.target.dataset.index);
				this.updateCurrent(index);
			});
		});

		this.el.previous.addEventListener("click", () => {
			this.navigateToPreviousSlide();
		});

		this.el.next.addEventListener("click", () => {
			this.navigateToNextSlide();
		});
	}

	navigateToPreviousSlide() {
		const newIndex =
			this.currentIndex > 0 ? this.currentIndex - 1 : this.el.items.length - 1;
		this.updateCurrent(newIndex);
	}

	navigateToNextSlide() {
		const newIndex =
			this.currentIndex < this.el.items.length - 1 ? this.currentIndex + 1 : 0;
		this.updateCurrent(newIndex);
	}

	updateCurrent(index) {
		this.disableButtons();
		this.currentIndex = index;

		// Handle transitions
		this.handleTestimonialState();
		this.transitionOut();
	}

	transitionOut() {
		const index = this.previousIndex;
		const items = this.el.items;
		const item = items[index];

		const el = {
			authorName: item.querySelector(".testimonials__author--name"),
			authorFlair: item.querySelector(".testimonials__author--flair"),
			quote: item.querySelector(".testimonials__quote"),
			image: item.querySelector(".testimonials__image"),
			imageClip: item.querySelector(".tesimonials__image--clip"),
		};

		const tl = gsap
			.timeline({
				default: {
					ease: "power3.in",
				},
				onComplete: () => {
					this.transitionIn();
				},
			})
			.to(
				[el.authorName, el.quote],
				{
					x: -40,
					autoAlpha: 0,
					duration: 0.3,
				},
				0
			)
			.to(
				el.image,
				{
					autoAlpha: 0,
					duration: 0.3,
				},
				0
			)
			.to(
				el.authorFlair,
				{
					scaleX: 0,
					duration: 0.3,
				},
				0
			);
	}

	transitionIn() {
		const index = this.currentIndex;
		const items = this.el.items;
		const item = items[index];

		const el = {
			authorName: item.querySelector(".testimonials__author--name"),
			authorFlair: item.querySelector(".testimonials__author--flair"),
			quote: item.querySelector(".testimonials__quote"),
			image: item.querySelector(".testimonials__image"),
			imageClip: item.querySelector(".tesimonials__image--clip"),
		};

		const tl = gsap
			.timeline({
				delay: 0.1,
				defaults: {
					ease: "power3.out",
				},
				onComplete: () => {
					this.enableButtons();
				},
			})
			.fromTo(
				el.image,
				{
					autoAlpha: 0,
				},
				{
					autoAlpha: 1,
					duration: 0.4,
				}
			)
			.fromTo(
				el.quote,
				{
					x: -40,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					duration: 0.4,
				},
				0.05
			)
			.fromTo(
				el.authorName,
				{
					x: -40,
					autoAlpha: 0,
				},
				{
					x: 0,
					autoAlpha: 1,
					duration: 0.4,
				},
				0.15
			)
			.fromTo(
				el.authorFlair,
				{
					scaleX: 0,
				},
				{
					scaleX: 1,
					duration: 0.4,
				},
				0.15
			);
	}

	disableButtons() {
		this.el.controls.forEach((control) => {
			control.disabled = true;
		});

		this.el.previous.disabled = true;
		this.el.next.disabled = true;
	}

	enableButtons() {
		this.previousIndex = this.currentIndex;

		this.el.controls.forEach((control) => {
			control.disabled = false;
		});

		this.el.previous.disabled = false;
		this.el.next.disabled = false;
	}

	handleTestimonialState() {
		const index = this.currentIndex;
		const items = this.el.items;
		const controls = this.el.controls;

		this.el.items.forEach((item) =>
			item.classList.remove("testimonials__item--active")
		);

		this.el.controls.forEach((control) =>
			control.classList.remove("testimonials__control--active")
		);

		// Set active control and item
		items[index].classList.add("testimonials__item--active");
		controls[index].classList.add("testimonials__control--active");
	}
}
