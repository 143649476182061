import DefaultBlock from "/scripts/default/block";
import SVGMorph from "./plugin/svgMorph";
import gsap from "gsap";

export default class Plugins extends DefaultBlock {
	init() {
		/* Check the page for all instances of the data-plugin attribute */
		this.plugins = document.querySelectorAll("[data-plugin]");
		this.DOM = {
			clubGsapBadges: this.block.querySelectorAll("[data-club-gsap-badge]"),
		};

		this.createPlugins();
	}

	createPlugins() {
		/* Loop through plugins */
		this.plugins.forEach((plugin) => {
			const pluginType = plugin.getAttribute("data-plugin");

			/* Pass each plugin to the createPlugin function */
			switch (pluginType) {
				/* Match the plugin name with the corresponding class that should be created */
				case "svg-morph-plugin":
					return new SVGMorph(plugin);
				default:
					return null;
			}
		});
	}

	initEvents() {
		if (!this.DOM.clubGsapBadges.length) return;

		this.DOM.clubGsapBadges.forEach((button) => {
			const badge = button.querySelector("svg");
			const badgePath = badge.querySelector("path");
			const tl = gsap.timeline({ paused: true });

			tl.to(badge, {
				rotateY: 90,
				duration: 0.3,
				ease: "power2.in",
			})
				.set(badgePath, { fill: "var(--color-shockingly-green)" })
				.to(badge, {
					rotateY: 180,
					duration: 0.3,
					ease: "power2.out",
				});

			button.addEventListener("mouseenter", () => {
				tl.play();
			});

			button.addEventListener("mouseleave", () => {
				tl.reverse();
			});
		});
	}
}
