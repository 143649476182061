import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { watchPreferredMotion } from "../../../utils/helpers/watchPreferredMotion";
import DefaultBlock from "/scripts/default/block";

// Register Plugins
gsap.registerPlugin(ScrollTrigger);

export default class Subtitle extends DefaultBlock {
	init() {
		const DOM = {
			wrap: this.block,
			braces: this.block.querySelectorAll(".subtitle__brace"),
			label: this.block.querySelectorAll(".subtitle__label"),
		};
		this.DOM = DOM;
		this.startDelay = Number(this.block.dataset.delay);

		this.buildOn();
	}

	buildOn() {
		const tl = gsap.timeline({
			defaults: {
				ease: "power3.out",
				duration: 0.3,
			},
			scrollTrigger: {
				trigger: this.block,
				start: "top 90%",
				once: true,
			},
		});

		gsap.set(this.DOM.wrap, { autoAlpha: 1 });

		watchPreferredMotion(() => {
			tl.from(this.DOM.label, {
				opacity: 0,
				duration: 0.7,
				delay: this.startDelay,
			})
				.from(
					this.DOM.braces[0],
					{
						opacity: 0,
						xPercent: 100,
					},
					"<0.1"
				)
				.from(
					this.DOM.braces[1],
					{
						opacity: 0,
						xPercent: -100,
					},
					"<"
				);
		});
	}
}
