/* Default Block */
export default class DefaultBlock {
	constructor(block) {
		/* Store the block element */
		this.block = block;

		/* Init */
		this.init();

		/* Init any event listeners */
		this.initEvents();
	}

	init() {}

	initEvents() {}
}
