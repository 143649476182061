import DefaultBlock from "/scripts/default/block";
import gsap from "gsap";
import { Draggable, InertiaPlugin } from "gsap/all";

gsap.registerPlugin(Draggable, InertiaPlugin);

export default class MoreLinks extends DefaultBlock {
	init() {
		const DOM = {
			wrapper: this.block,
			container: this.block.querySelector(".tools-morelinks__main"),
			carousel: this.block.querySelector(".tools-morelinks__items"),
			items: this.block.querySelectorAll(".tools-morelinks__item"),
		};
		this.DOM = DOM;

		this.initMoreLinks();
	}

	setBounds() {
		Draggable.get(this.DOM.carousel).applyBounds({
			minX:
				-this.DOM.carousel.offsetWidth + this.DOM.container.offsetWidth + 16,
			maxX: 0,
		});
	}

	createCarousel() {
		Draggable.create(this.DOM.carousel, {
			type: "x",
			edgeResistance: 1,
			inertia: true,
			maxDuration: 0.5,
			snap: {
				x: (x) => {
					const itemWidth = this.DOM.items[0].offsetWidth;
					const snapX = gsap.utils.snap(itemWidth, x);

					return snapX === 0 ? 0 : snapX;
				},
			},
		});

		this.setBounds();
	}

	initMoreLinks() {
		if (!this.DOM.items) return;

		this.createCarousel();

		window.addEventListener("resize", this.setBounds.bind(this));
	}
}
