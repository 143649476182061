import gsap from "gsap";
import DefaultBlock from "/scripts/default/block";
import { CustomEase } from "gsap/all";
import { watchPreferredMotion } from "/utils/helpers/watchPreferredMotion";

gsap.registerPlugin(CustomEase);

export default class GetGsapBtn extends DefaultBlock {
	init() {
		const selector = gsap.utils.selector(this.block);

		const DOM = {
			block: this.block,
			get: selector(".get-gsap-btn__word:first-child"),
			gsap: selector(".get-gsap-btn__word:last-child"),
			icons: selector(".get-gsap-btn__button svg"),
			circles: selector("#btn-circles"),
			windmill: selector("#btn-windmill"),
			square: selector("#btn-square"),
			star: selector("#btn-star"),
		};
		this.DOM = DOM;

		this.eases = {
			airtime: CustomEase.create(
				"custom",
				"M0,0 C0.05,0.356 0.377,0.435 0.5,0.5 0.61,0.558 0.948,0.652 1,1 "
			),
			rotaaaaate: CustomEase.create(
				"custom",
				"M0,0 C0.148,0.346 0.254,0.444 0.5,0.5 0.751,0.557 0.852,0.646 1,1 "
			),
		};

		this.playing = false;
		this.tl = this.createTimeline();
		this.playTimeline = this.playTimeline.bind(this);
	}

	initEvents() {
		const mm = gsap.matchMedia();

		mm.add(
			"(min-width: 1240px) and (prefers-reduced-motion: no-preference)",
			() => {
				this.DOM.block.addEventListener("mouseenter", this.playTimeline);

				return () => {
					this.DOM.block.removeEventListener("mouseenter", this.playTimeline);
				};
			}
		);
	}

	createTimeline() {
		const tl = gsap.timeline({
			defaults: {
				duration: 1,
			},
			paused: true,
			onStart: () => {
				this.playing = true;
			},
			onComplete: () => {
				this.playing = false;
			},
		});

		gsap.set(
			[this.DOM.circles, this.DOM.windmill, this.DOM.square, this.DOM.star],
			{ scale: 0 }
		);

		tl.set(
			[this.DOM.circles, this.DOM.windmill, this.DOM.square, this.DOM.star],
			{ scale: 0, x: 0, y: 10, rotateZ: 0 }
		)
			.set(this.DOM.icons[0], { yPercent: -140 })
			.set(this.DOM.icons[1], { yPercent: 0 })
			.to(this.DOM.get, {
				keyframes: [
					{ x: -30, ease: "power4.out" },
					{ x: 0, ease: "power4.in" },
				],
			})
			.to(
				this.DOM.gsap,
				{
					keyframes: [
						{ x: 30, ease: "power4.out" },
						{ x: 0, ease: "power4.in" },
					],
				},
				"<"
			)
			.to(
				this.DOM.icons[0],
				{
					yPercent: 0,
					duration: 0.6,
					ease: "power3.in",
				},
				"<.3"
			)
			.to(
				this.DOM.icons[1],
				{
					yPercent: 140,
					duration: 0.6,
					ease: "power3.out",
				},
				"<"
			)
			.to(
				[this.DOM.circles, this.DOM.windmill, this.DOM.square, this.DOM.star],
				{
					keyframes: [
						{ scale: 0, zIndex: 2, duration: 0 },
						{
							y: () => {
								return gsap.utils.random(-80, -120);
							},
							scale: 1,
						},
						{ zIndex: -1, duration: 0.05 },
						{
							y: 0,
							scale: 0.3,
						},
					],
					ease: this.eases.airtime,
					stagger: 0.15,
				},
				"<"
			)
			.to(
				[this.DOM.circles, this.DOM.windmill, this.DOM.square, this.DOM.star],
				{
					x: () => {
						return gsap.utils.random(-50, 100);
					},
					rotateZ: () => -360,
					ease: this.eases.rotaaaaate,
					stagger: 0.15,
				},
				"<"
			);

		return tl;
	}

	playTimeline() {
		if (this.playing) return;

		this.tl.invalidate().play(0);
	}
}
