import DefaultBlock from "/scripts/default/block";
import MicroModal from "micromodal";

export default class Tooltip extends DefaultBlock {
	init() {
		this.DOM = {
			buttons: this.block.querySelectorAll(".button"),
		};

		MicroModal.init({
			disableScroll: true,
		});
	}

	initEvents() {
		this.DOM.buttons.forEach((button) => {
			button.addEventListener("click", () => {
				// Timeout is needed as MicroModal was sometimes not closing the modal correctly
				setTimeout(() => {
					MicroModal.close(this.block.id);
				}, 10);
			});
		});
	}
}
