import gsap from "gsap";
import DefaultBlock from "/scripts/default/block";
import { watchPreferredMotion } from "/utils/helpers/watchPreferredMotion";

export default class Brands extends DefaultBlock {
	init() {
		this.DOM = {
			columns: this.block.querySelectorAll(".brands__item"),
		};

		this.column = {
			one: this.DOM.columns[0].innerHTML,
			two: this.DOM.columns[1].innerHTML,
			three: this.DOM.columns[2].innerHTML,
			four: this.DOM.columns[3].innerHTML,
			five: this.DOM.columns[4].innerHTML,
			six: this.DOM.columns[5].innerHTML,
		};

		this.createTimeline();
	}

	createTimeline() {
		const mm = gsap.matchMedia();

		mm.add(
			{
				isMobile: "(max-width: 768px)",
				isDesktop: "(min-width: 769px ) and (max-width: 1240px)",
				isLargeDesktop: "(min-width: 1241px)",
			},
			(context) => {
				let columnsLength;

				if (context.conditions.isMobile) {
					columnsLength = 3;
					this.DOM.columns[0].innerHTML = [this.column.one + this.column.two];
					this.DOM.columns[1].innerHTML = [
						this.column.three + this.column.four,
					];
					this.DOM.columns[2].innerHTML = [this.column.five + this.column.six];
				} else if (context.conditions.isDesktop) {
					columnsLength = 5;
					this.DOM.columns[0].innerHTML = [this.column.one + this.column.two];
					this.DOM.columns[1].innerHTML = this.column.three;
					this.DOM.columns[2].innerHTML = this.column.four;
					this.DOM.columns[3].innerHTML = this.column.five;
					this.DOM.columns[4].innerHTML = this.column.six;
				} else if (context.conditions.isLargeDesktop) {
					columnsLength = 6;
					this.DOM.columns[0].innerHTML = this.column.one;
					this.DOM.columns[1].innerHTML = this.column.two;
					this.DOM.columns[2].innerHTML = this.column.three;
					this.DOM.columns[3].innerHTML = this.column.four;
					this.DOM.columns[4].innerHTML = this.column.five;
					this.DOM.columns[5].innerHTML = this.column.six;
				}

				watchPreferredMotion(() => {
					for (let i = 0; i < columnsLength; i++) {
						const column = this.DOM.columns[i];
						const logos = column.querySelectorAll("svg");
						const randomOffset = gsap.utils.random(["-200%", "200%"]);
						const isEven = i % 2 === 0;

						const tl = gsap.timeline({
							repeat: -1,
							delay: -columnsLength + i * 0.2,
						});

						logos.forEach((logo) => {
							tl.to(logo, {
								keyframes: [
									{
										y: isEven ? randomOffset : 0,
										x: isEven ? 0 : randomOffset,
										duration: 0.3,
									},
									{
										autoAlpha: 1,
										x: 0,
										y: 0,
										duration: 0.5,
										ease: "power2.out",
									},
									{
										delay: 3,
										y: isEven ? 0 : randomOffset,
										x: isEven ? randomOffset : 0,
										duration: 0.3,
										ease: "power2.in",
									},
								],
							}).set(logo, {
								autoAlpha: 0,
							});
						});
					}
				});
			}
		);
	}
}
