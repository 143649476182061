/* Default Factory */
export default class DefaultFactory {
	constructor() {
		/* Check the page for all instances of the data-block attribute */
		this.blocks = document.querySelectorAll("[data-block]");

		/* Init */
		this.init();
	}

	init() {
		/* Loop through blocks */
		this.blocks.forEach((block) => {
			const blockType = block.getAttribute("data-block");

			/* Pass each block to the createBlock function */
			this.createBlock(blockType, block);
		});
	}

	/*
		createBlock is empty by default, this function should be
		recreated for each instance with the blocks to be checked for
	*/
	createBlock() {
		console.error("No createBlock for this factory");
		return null;
	}
}
