import Player from "@vimeo/player";
import DefaultBlock from "/scripts/default/block";

export default class Video extends DefaultBlock {
	init() {
		this.id = this.block.getAttribute("data-id");
		this.cover = this.block.classList.contains("video--cover");

		var options = {
			id: this.id,
			background: this.cover,
		};

		this.player = new Player(this.block, options);

		if (this.cover) {
			Promise.all([
				this.player.getVideoWidth(),
				this.player.getVideoHeight(),
			]).then((dimensions) => {
				const [width, height] = dimensions;
				this.aspectRatio = width / height;
				this.updatePosition();
			});
		}

		this.updatePosition = this.updatePosition.bind(this);
	}

	initEvents() {
		this.cover && window.addEventListener("resize", this.updatePosition);
	}

	updatePosition() {
		const parentBounds = this.block.getBoundingClientRect();
		const parentAspectRatio = parentBounds.width / parentBounds.height;

		if (parentAspectRatio < this.aspectRatio) {
			this.player.element.style.width = `${
				(this.aspectRatio / parentAspectRatio) * 100
			}%`;
			this.player.element.style.height = "";
		} else {
			this.player.element.style.height = `${
				(parentAspectRatio / this.aspectRatio) * 100
			}%`;
			this.player.element.style.width = "";
		}
	}
}
